var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Using custom form components" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeCustom) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", [
        _vm._v(
          " The scope contains attributes and event handlers that can be directly bound to most custom inputs or select components. "
        )
      ]),
      _c(
        "div",
        [
          _c(
            "b-form-group",
            { attrs: { label: "Tagged input using select" } },
            [
              _c("b-form-tags", {
                attrs: {
                  size: "lg",
                  "add-on-change": "",
                  "no-outer-focus": ""
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var tags = ref.tags
                      var inputAttrs = ref.inputAttrs
                      var inputHandlers = ref.inputHandlers
                      var disabled = ref.disabled
                      var removeTag = ref.removeTag
                      return [
                        tags.length > 0
                          ? _c(
                              "ul",
                              {
                                staticClass: "list-inline d-inline-block mb-1"
                              },
                              _vm._l(tags, function(tag) {
                                return _c(
                                  "li",
                                  { key: tag, staticClass: "list-inline-item" },
                                  [
                                    _c(
                                      "b-form-tag",
                                      {
                                        attrs: {
                                          title: tag,
                                          disabled: disabled,
                                          variant: "primary"
                                        },
                                        on: {
                                          remove: function($event) {
                                            return removeTag(tag)
                                          }
                                        }
                                      },
                                      [_vm._v(" " + _vm._s(tag) + " ")]
                                    )
                                  ],
                                  1
                                )
                              }),
                              0
                            )
                          : _vm._e(),
                        _c(
                          "b-form-select",
                          _vm._g(
                            _vm._b(
                              {
                                attrs: {
                                  disabled:
                                    disabled ||
                                    _vm.availableOptions.length === 0,
                                  options: _vm.availableOptions
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "first",
                                      fn: function() {
                                        return [
                                          _c(
                                            "option",
                                            {
                                              attrs: { disabled: "", value: "" }
                                            },
                                            [_vm._v(" Choose a tag... ")]
                                          )
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ],
                                  null,
                                  true
                                )
                              },
                              "b-form-select",
                              inputAttrs,
                              false
                            ),
                            inputHandlers
                          )
                        )
                      ]
                    }
                  }
                ]),
                model: {
                  value: _vm.value,
                  callback: function($$v) {
                    _vm.value = $$v
                  },
                  expression: "value"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }