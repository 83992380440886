var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Last tag removal via backspace keypress" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeRemoval) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", [
        _c("span", [_vm._v("When the prop ")]),
        _c("code", [_vm._v("remove-on-delete")]),
        _c("span", [_vm._v(" is set, and the user presses ")]),
        _c("code", [_vm._v("Backspace")]),
        _c("span", [_vm._v(" (or ")]),
        _c("code", [_vm._v("Del")]),
        _c("span", [
          _vm._v(
            ") and the input value is empty, the last tag in the tag list will be removed. "
          )
        ])
      ]),
      _c(
        "div",
        [
          _c("label", { attrs: { for: "tags-remove-on-delete" } }, [
            _vm._v("Enter new tags separated by space")
          ]),
          _c("b-form-tags", {
            staticClass: "mb-2",
            attrs: {
              "input-id": "tags-remove-on-delete",
              "input-attrs": {
                "aria-describedby": "tags-remove-on-delete-help"
              },
              separator: " ",
              placeholder: "Enter new tags separated by space",
              "remove-on-delete": "",
              "no-add-on-enter": ""
            },
            model: {
              value: _vm.value,
              callback: function($$v) {
                _vm.value = $$v
              },
              expression: "value"
            }
          }),
          _c("b-form-text", { attrs: { id: "tags-remove-on-delete-help" } }, [
            _vm._v(" Press "),
            _c("code", [_vm._v("Backspace")]),
            _vm._v(" to remove the last tag entered ")
          ]),
          _c("b-card-text", [_vm._v("Value: " + _vm._s(_vm.value))])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }