var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-row",
    [
      _c(
        "b-col",
        { attrs: { cols: "12" } },
        [
          _c("basic-form-tags"),
          _c("separators-form-tags"),
          _c("removal-form-tags"),
          _c("styling-form-tags"),
          _c("validation-form-tags"),
          _c("detecting-form-tags"),
          _c("native-input-form-tags"),
          _c("custom-form-tags"),
          _c("advance-custom-form-tags")
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }