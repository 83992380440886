var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Tag creation using separators" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeSeparators) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", [
        _c("span", [
          _vm._v(
            "To auto create tags when a separator character is typed (i.e. "
          )
        ]),
        _c("b", [_vm._v("Space")]),
        _c("span", [_vm._v(", ")]),
        _c("b", [_vm._v(",")]),
        _c("span", [_vm._v(", etc), set the")]),
        _c("code", [_vm._v("separator")]),
        _c("span", [
          _vm._v(
            " prop to the character that will trigger the tag to be added. "
          )
        ])
      ]),
      _c(
        "div",
        [
          _c("label", { attrs: { for: "tags-separators" } }, [
            _vm._v("Enter tags separated by space, comma or semicolon")
          ]),
          _c("b-form-tags", {
            staticClass: "mb-2",
            attrs: {
              "input-id": "tags-separators",
              separator: " ,;",
              placeholder:
                "Enter new tags separated by space, comma or semicolon",
              "no-add-on-enter": ""
            },
            model: {
              value: _vm.value,
              callback: function($$v) {
                _vm.value = $$v
              },
              expression: "value"
            }
          }),
          _c("b-card-text", [_vm._v("Value: " + _vm._s(_vm.value))])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }